<template>
    <div>
    </div>
</template>

<script>
import monitor from "./store/index.js"
export default {
    created() {
        if (!this.$store.hasModule("monitor"))
            this.$store.registerModule("monitor", monitor)
    }
}
</script>